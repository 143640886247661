exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-animalcalc-tsx": () => import("./../../../src/pages/animalcalc.tsx" /* webpackChunkName: "component---src-pages-animalcalc-tsx" */),
  "component---src-pages-backgrounds-tsx": () => import("./../../../src/pages/backgrounds.tsx" /* webpackChunkName: "component---src-pages-backgrounds-tsx" */),
  "component---src-pages-calculators-tsx": () => import("./../../../src/pages/calculators.tsx" /* webpackChunkName: "component---src-pages-calculators-tsx" */),
  "component---src-pages-cards-tsx": () => import("./../../../src/pages/cards.tsx" /* webpackChunkName: "component---src-pages-cards-tsx" */),
  "component---src-pages-drop-pain-tsx": () => import("./../../../src/pages/drop-pain.tsx" /* webpackChunkName: "component---src-pages-drop-pain-tsx" */),
  "component---src-pages-emblems-tsx": () => import("./../../../src/pages/emblems.tsx" /* webpackChunkName: "component---src-pages-emblems-tsx" */),
  "component---src-pages-exchange-center-tsx": () => import("./../../../src/pages/exchange-center.tsx" /* webpackChunkName: "component---src-pages-exchange-center-tsx" */),
  "component---src-pages-exploring-tsx": () => import("./../../../src/pages/exploring.tsx" /* webpackChunkName: "component---src-pages-exploring-tsx" */),
  "component---src-pages-farmhousecalc-tsx": () => import("./../../../src/pages/farmhousecalc.tsx" /* webpackChunkName: "component---src-pages-farmhousecalc-tsx" */),
  "component---src-pages-fishing-tsx": () => import("./../../../src/pages/fishing.tsx" /* webpackChunkName: "component---src-pages-fishing-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-level-rewards-tsx": () => import("./../../../src/pages/level-rewards.tsx" /* webpackChunkName: "component---src-pages-level-rewards-tsx" */),
  "component---src-pages-orchardcalc-tsx": () => import("./../../../src/pages/orchardcalc.tsx" /* webpackChunkName: "component---src-pages-orchardcalc-tsx" */),
  "component---src-pages-passwords-tsx": () => import("./../../../src/pages/passwords.tsx" /* webpackChunkName: "component---src-pages-passwords-tsx" */),
  "component---src-pages-quests-tsx": () => import("./../../../src/pages/quests.tsx" /* webpackChunkName: "component---src-pages-quests-tsx" */),
  "component---src-pages-quizzes-tsx": () => import("./../../../src/pages/quizzes.tsx" /* webpackChunkName: "component---src-pages-quizzes-tsx" */),
  "component---src-pages-random-tsx": () => import("./../../../src/pages/random.tsx" /* webpackChunkName: "component---src-pages-random-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-servertime-tsx": () => import("./../../../src/pages/servertime.tsx" /* webpackChunkName: "component---src-pages-servertime-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-tower-tsx": () => import("./../../../src/pages/tower.tsx" /* webpackChunkName: "component---src-pages-tower-tsx" */),
  "component---src-pages-towercalc-tsx": () => import("./../../../src/pages/towercalc.tsx" /* webpackChunkName: "component---src-pages-towercalc-tsx" */),
  "component---src-pages-townsfolk-grid-tsx": () => import("./../../../src/pages/townsfolk-grid.tsx" /* webpackChunkName: "component---src-pages-townsfolk-grid-tsx" */),
  "component---src-pages-townsfolk-tsx": () => import("./../../../src/pages/townsfolk.tsx" /* webpackChunkName: "component---src-pages-townsfolk-tsx" */),
  "component---src-pages-wheelcalc-tsx": () => import("./../../../src/pages/wheelcalc.tsx" /* webpackChunkName: "component---src-pages-wheelcalc-tsx" */),
  "component---src-pages-wikigen-tsx": () => import("./../../../src/pages/wikigen.tsx" /* webpackChunkName: "component---src-pages-wikigen-tsx" */),
  "component---src-pages-winecalc-tsx": () => import("./../../../src/pages/winecalc.tsx" /* webpackChunkName: "component---src-pages-winecalc-tsx" */),
  "component---src-pages-xpcalc-tsx": () => import("./../../../src/pages/xpcalc.tsx" /* webpackChunkName: "component---src-pages-xpcalc-tsx" */),
  "component---src-templates-item-tsx": () => import("./../../../src/templates/item.tsx" /* webpackChunkName: "component---src-templates-item-tsx" */),
  "component---src-templates-location-tsx": () => import("./../../../src/templates/location.tsx" /* webpackChunkName: "component---src-templates-location-tsx" */),
  "component---src-templates-npc-tsx": () => import("./../../../src/templates/npc.tsx" /* webpackChunkName: "component---src-templates-npc-tsx" */),
  "component---src-templates-pet-tsx": () => import("./../../../src/templates/pet.tsx" /* webpackChunkName: "component---src-templates-pet-tsx" */),
  "component---src-templates-quest-tsx": () => import("./../../../src/templates/quest.tsx" /* webpackChunkName: "component---src-templates-quest-tsx" */),
  "component---src-templates-questline-tsx": () => import("./../../../src/templates/questline.tsx" /* webpackChunkName: "component---src-templates-questline-tsx" */),
  "component---src-templates-quiz-tsx": () => import("./../../../src/templates/quiz.tsx" /* webpackChunkName: "component---src-templates-quiz-tsx" */)
}

